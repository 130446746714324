import React from 'react'
import * as Styled from './style'

const ConfirmCloseChat = ({openPopover, setOpen, anchorEl, setAnchorEl}) => {
  return (
    <Styled.Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <div>
          <h3 className='digitalk-titlePopover'>Quer realmente finalizar esta conversa?</h3>
          <Styled.ButtonContainer>
          <button className='digitalk-confirm' onClick={() => {
            setOpen(false)
            setAnchorEl(null)
          }}>Terminar chat</button>
          <button className='digitalk-cancel' onClick={() => setAnchorEl(null)}>Cancelar</button>
          </Styled.ButtonContainer>
        </div>
      </Styled.Popover>
  )
}

export default ConfirmCloseChat