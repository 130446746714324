import styled, { createGlobalStyle } from "styled-components";
import ButtonStyle from "@material-ui/core/Button";
import IconButtonStyle from "@material-ui/core/IconButton";
export const GlobalStyle = createGlobalStyle`

  #digitalk-chat {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
  }
    
  #digitalk-chat {
    display: flex;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
    
  #digitalk-chat .hidden {
     display: none;
  }

`;

export const Button = styled(ButtonStyle)`
  && {
    position: fixed;
    right: 32px;
    bottom: 32px;
    background-color: #FF6801;
    color: #ffffff;
    width: 64px;
    height: 64px;

    border-radius: 50%;

    &:hover {
      background-color: #ED6000;
    }

    span {
      font-size: 18px;
      text-wrap: none;
      text-transform: none;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

export const IconButton = styled(IconButtonStyle)`
  && {
    cursor: pointer;
    svg {
      height: 13px;
    }

    &.digitalk-close {
      color: #333333;
    }

    &.digitalk-arrowDown {
      color: #333333;
    }
  }
`;

export const Header = styled.div`
  color: #FF6801;
  display: flex;
  height: 45px;
  justify-content: space-between;
  padding: 0 6px 0 33px;
  align-items: center;
  background: #F0F0F0;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 18px;
  }
  img {
    height: 30px;
  }
`;

export const Container = styled.div`
  width: 320px;
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;
  height: 580px;
  min-height: 580px;
  max-height: 100%;
  background: #ffffff;
  position: fixed;
  right: 32px;
  bottom: ${({ hidden }) => (hidden ? "-600px" : "32px")};
  filter: drop-shadow(0px 30px 40px rgba(0, 0, 0, 0.15));
  z-index: 999;

  img {
    margin-right: 5px;
  }

  h2.digitalk-titleLogo {
    color: #FF6801;
    font-size: 14px;
  }

  @media (max-height: 540px) {
    bottom: 0;
    max-height: 400px;
  }

  @media (max-width: 500px) {
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    right: 0;
    bottom: 0;
  }
`;
