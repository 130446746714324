import LogoColor from "assets/images/brands/Logo-Trackview.png";
import LogoWhite from "assets/images/brands/Logo-Trackview.png";

export const Wallet = {
  track_view: {
    name: "TrackView",
    shortname: "track_view",
    logo_color: LogoColor,
    logo_white: LogoWhite,
    token: "3057631959a7c68ae0f3c95d72cf9058",
    color: {
      primary: "#FF6801",
      secondary: "#fff",
    },
  },
};
