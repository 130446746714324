import React, { Suspense, useState } from "react";

const WidgetComponent = React.lazy(() => import("../widget"));

const AccessForm = (props) => {
  const [chat, setChat] = useState(false);
  return (
    <Suspense fallback={<></>}>
      <WidgetComponent form={chat} {...props} />
    </Suspense>
  );
};

export default AccessForm;
