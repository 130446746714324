import styled from "styled-components";
import { Popover as PopoverStyle } from "@material-ui/core";

export const Popover = styled(PopoverStyle)(({ theme }) => ({
  ".MuiPaper-root": {
    width: "300px",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "35px 10px 0px -20px",

    "& .digitalk-titlePopover": {
      marginTop: "10px",
      fontSize: "14px",
      fontWeight: "600",
      textAlign: "center",
      fontFamily: "Quicksand",
      color: "#000",
    },
  },
}));

export const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  padding: "10px",

  "& button": {
    width: "100%",
    height: "30px",
    borderRadius: "5px",
    border: "none",
    fontSize: "12px",
    fontWeight: "600",
    transition: "0.3s ease",
    cursor: "pointer",

    "&.digitalk-cancel": {
      backgroundColor: "#ccc",
      color: "#262626",

      "&:hover": {
        backgroundColor: "#b5b4b4",
        color: "#000",
      },
    },
    "&.digitalk-confirm": {
      backgroundColor: "#FF6801",
      color: "#ffffff",

      "&:hover": {
        backgroundColor: "#ED6000",
      },
    },
  },
}));
