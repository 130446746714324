import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyle, Button, Header, IconButton, Container } from "./style";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import LogoWhite from "assets/images/brands/Logo-Trackview.png";
import { ReactComponent as CloseIcon } from "assets/images/icon/close.svg";
import { ReactComponent as ArrowDownIcon } from "assets/images/icon/arrow-down.svg";
import Form from "component/form";
import { Wallet } from "config";
import { ReactComponent as ChatIcon } from "assets/images/brands/iconChat.svg";
import ConfirmCloseChat from "component/confirmCloseChat";
//import GenerateManifest from "./generate_manifest";
const pathname = "track_view";
const currWallet = Wallet[pathname];
const theme = createMuiTheme({
  palette: {
    primary: {
      main: currWallet.color.primary,
    },
    secondary: {
      main: currWallet.color.secondary,
    },
  },
});

function App() {
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);

  useEffect(() => {
    //localStorage.clear()
    //GenerateManifest({...currWallet, pathname, url: window.location.href})
    window.chtken = currWallet.token;
    document.title = currWallet.name;
  }, [open]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {open && hidden && (
          <Button
            variant="contained"
            classes={{ label: "button_chat" }}
            color="primary"
            onClick={() => {
              setHidden(!hidden);
            }}
          >
            <ChatIcon></ChatIcon>
          </Button>
        )}
        {!open ? (
          <Button
            variant="contained"
            classes={{ label: "button_chat" }}
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <ChatIcon></ChatIcon>
          </Button>
        ) : (
          <Container hidden={hidden}>
            <Header>
              <span />
              <span />
              <div>
                <img src={LogoWhite} alt="Logo" />
                <h2 className="digitalk-titleLogo"><span style={{ color: "#333333" }}>Track</span>view</h2>
              </div>
              <div>
                <IconButton
                  onClick={() => setHidden(!hidden)}
                  className="digitalk-arrowDown"
                >
                  <ArrowDownIcon />
                </IconButton>
                <IconButton
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  className="digitalk-close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Header>
            <Form
              pathname={pathname}
              wallet={currWallet}
              handleClose={() => setOpen(false)}
            />
          </Container>
        )}

        <ConfirmCloseChat
          openPopover={openPopover}
          setOpen={setOpen}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />

        <ToastContainer
          position="bottom-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </>
  );
}

export default App;
